<template>
  <div class="footer">
    <v-container>
      <v-row gutters="12">
        <v-col cols="12" md="6">
          <v-img class="logo" src="@/assets/images/logo.png" alt=""> </v-img>
        </v-col>
        <v-col cols="12" md="3">
          <h3>Company:</h3>
          <ul>
            <li><a href="javascript:" @click="$router.push('/about')">Our Story</a></li>
            <li><a href="javascript:" @click="$router.push('/features')">Product Features</a></li>
          </ul>
        </v-col>
        <v-col cols="12" md="3">
          <h3>Contact:</h3>
          <ul>
            <li><a href="mailto:info@oneclinic.pk">info@oneclinic.pk</a></li>
            <li><a @click="$router.push('/contact')">Request Pricing</a></li>
            <li><a @click="$router.push('/contact')">Book a Demo</a></li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
</script>

<style scoped>

.footer {
  padding: 30px 0;
  background: #222222;
}
.footer .footer-logo {
  width: 160px;
}
.footer p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  margin: 40px 0 15px 0;
}
.footer h3 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 15px 0 15px 0;
}
.footer ul li {
  list-style: none;
  line-height: 30px;
}
.footer ul li a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0.5px;
}
.footer ul li a:hover {
  color: #2f74bf;
}
.footer .w-140 {
  width: 140px;
}

/* Extra Small Media Query Start Here */
@media (min-width: 350px) and (max-width: 600px) {
  .footer {
    padding: 10px 0;
  }
  .footer a img {
    width: 140px;
  }
}
/* Extra Small Media Query End Here */

</style>