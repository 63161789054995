<template>
  <div class="content-wrapper">
    <div class="pa-4">
      <h1>Editor</h1>
      <div>
        <v-text-field
          label="Title"
          variant="outlined"
          v-model="pagetitle"
        ></v-text-field>
      </div>
      <div v-for="(itm, i) of pageitems" :key="i" class="mb-10">
        <v-row gutters="16" v-if="itm.type === 'text'">
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="position-relative">
              <v-textarea
                class="mt-5"
                label="Markdown Content"
                variant="outlined"
                v-model="itm.value"
                rows="15"
              ></v-textarea>
              <v-btn
                class="file-upload-btn"
                color="primary"
                fab
                x-small
                dark
                @click="openRefGetter(itm, 'value')"
              >
                <v-icon>mdi-cloud-upload</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <Markdown
              :breaks="true"
              :html="true"
              :source="cleanText(itm.value)"
            />
          </v-col>
        </v-row>
        <div v-if="itm.type === 'timeline'">
          <div class="d-flex align-center">
            <h2>Timeline</h2>
            <div class="mt-3 mb-3 ml-4">
              <v-btn @click="addTimeline(itm.value)" color="primary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <v-text-field
              label="Timeline Title"
              variant="outlined"
              v-model="itm.title"
            ></v-text-field>
          </div>

          <div>
            <div v-for="(item, index) in itm.value" :key="index">
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <h3 class="mb-6 d-flex align-center justify-space-between">
                    <span>
                      Timeline Item {{ index + 1 }}
                      <v-icon class="drag-icon" dark color="primary">
                        mdi-drag
                      </v-icon>
                    </span>
                    <span>
                      <v-btn color="primary" class="timeline-delete-btn">
                        <v-icon dark> mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </span>
                  </h3>
                  <v-row gutters="16">
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="4" lg="4">
                      <v-text-field
                        label="Title"
                        variant="outlined"
                        v-model="item.title"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="4" lg="4">
                      <v-text-field
                        label="Year"
                        variant="outlined"
                        v-model="item.year"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="4" lg="4">
                      <v-text-field
                        label="Source Rating"
                        variant="outlined"
                        v-model="item.sourcerating"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="12" lg="12">
                      <v-text-field
                        label="Source Address"
                        variant="outlined"
                        v-model="item.sourceaddress"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="12" lg="12">
                      <div class="position-relative">
                        <v-textarea
                          rows="4"
                          label="Content"
                          variant="outlined"
                          v-model="item.content"
                        ></v-textarea>
                        <v-btn
                          class="file-upload-btn"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click="openRefGetter(item, 'content')"
                        >
                          <v-icon>mdi-cloud-upload</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="12" md="12" lg="12">
                      <div class="position-relative">
                        <v-textarea
                          rows="4"
                          label="Detail Content"
                          variant="outlined"
                          v-model="item.detailcontent"
                        ></v-textarea>
                        <v-btn
                          class="file-upload-btn"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click="openRefGetter(item, 'detailcontent')"
                        >
                          <v-icon>mdi-cloud-upload</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-timeline class="mt-10 mb-10" align="start" side="end">
                    <v-timeline-item color="primary">
                      <template v-slot:opposite>
                        <div
                          :class="`pt-1 headline font-weight-bold `"
                          v-text="item.year"
                        ></div>
                      </template>
                      <div style="max-width: 400px">
                        <h2 :class="`mt-n1 headline font-weight-light mb-3 `">
                          {{ item.title }}
                        </h2>
                        <div>
                          <Markdown
                            :breaks="true"
                            :html="true"
                            :source="item.content"
                          />
                        </div>
                        <div class="mt-2">
                          <v-rating :model-value="item.sourcerating" readonly />
                        </div>
                        <div>
                          <v-btn
                            class="mr-2"
                            variant="outlined"
                            color="primary"
                          >
                            Detail
                          </v-btn>
                          <v-btn variant="outlined" color="primary">
                            Check
                          </v-btn>
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
              <!-- <hr /> -->
            </div>
          </div>
        </div>
        <!-- <hr class="mt-10" /> -->
        <!-- <v-row gutters="16" class="mt-10">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-textarea
              class="mt-5"
              label="Bottom Content"
              variant="outlined"
              v-model="bottomtext"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <Markdown :breaks="true" :html="true" :source="bottomtext" />
          </v-col>
        </v-row> -->
      </div>
      <div>
        <v-btn @click="addTextItem" color="primary">
          <v-icon>mdi-plus</v-icon> <v-icon>mdi-text</v-icon>
        </v-btn>
        <v-btn class="ml-5" @click="addTimelineItem" color="primary">
          <v-icon>mdi-plus</v-icon> <v-icon>mdi-timeline-text-outline</v-icon>
        </v-btn>
        <v-btn class="ml-5" @click="refgetterdialog = true" color="primary">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </div>
    </div>
    <v-container>
      <h2>Full Article</h2>
      <hr />
      <h1 class="mb-10">{{pagetitle}}</h1>
      <div v-for="(itm, i) of pageitems" :key="i" class="mb-10">
        <Markdown
          v-if="itm.type === 'text'"
          :breaks="true"
          :html="true"
          :source="cleanText(itm.value)"
        />
        <h3 v-if="itm.type === 'timeline'" class="mt-10 mb-10">
          {{ itm.title }}
        </h3>
        <v-timeline
          v-if="itm.type === 'timeline'"
          class="mt-10 mb-10"
          align="start"
          side="end"
        >
          <v-timeline-item
            v-for="(item, index) in itm.value"
            :key="index"
            color="primary"
          >
            <template v-slot:opposite>
              <div
                :class="`pt-1 headline font-weight-bold `"
                v-text="item.year"
              ></div>
            </template>
            <div style="max-width: 400px">
              <h2 :class="`mt-n1 headline font-weight-light mb-3 `">
                {{ item.title }}
              </h2>
              <div>
                <Markdown :breaks="true" :html="true" :source="item.content" />
              </div>
              <div class="mt-2">
                <v-rating :model-value="item.sourcerating" readonly />
              </div>
              <div>
                <v-btn class="mr-2" variant="outlined" color="primary">
                  Detail
                </v-btn>
                <v-btn variant="outlined" color="primary"> Check </v-btn>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
        <!-- <Markdown :breaks="true" :html="true" :source="bottomtext" /> -->
      </div>
    </v-container>
    <v-dialog
      persistent=""
      fullscreen
      v-if="refgetterdialog"
      v-model="refgetterdialog"
    >
      <v-card>
        <v-card-title class="headline pb-3 justify-space-between">
          <!-- Get References -->
          <v-btn
            class="close-modal-btn"
            color="primary"
            fab
            x-small
            dark
            @click="refgetterdialog = false"
            aria-label="Ref Getter"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-0">
          <RefGetterVue
            :fieldtext="objref"
            :field="objreffield"
            :updatefunc="updateField"
          />
          <!-- <AssistedGrading :questionindex="activeQuestionIndex" /> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RefGetterVue from "@/components/RefGetter.vue";
import Markdown from "vue3-markdown-it";
export default {
  name: "Editor",
  components: {
    Markdown,
    RefGetterVue,
  },

  data() {
    return {
      toptext: "",
      timeline: [],
      bottomtext: "",
      pageitems: [],
      pagetitle: "Article about Dolphins",
      refgetterdialog: false,
      objref: null,
      objreffield: "value",
    };
  },
  mounted() {
    //write a long paragraph with a story using makrdown
    this.toptext = `## Dolphins: Enchanting Creatures of the Sea

Dolphins, with their grace and intelligence, hold a special place in our hearts. Here's a brief look at these remarkable marine mammals.
\\np
### Species and Habitat


**Diverse Species:** Over 40 species, including bottlenose, orca, and spinner dolphins.
**Wide Range:** Found in oceans, rivers, and various climate zones.
\\np

### Physical Features

**Streamlined Bodies:** Ideal for agile swimming.
**Distinctive Markings:** Dorsal fin, horizontal tail fluke, and blowhole for breathing.

\\np

### Intelligence and Social Behavior
**Highly Intelligent:** Known for problem-solving and complex communication.
**Social Creatures:** Form pods for hunting, play, and nurturing.`;

    this.timeline.push({
      title: "Title",
      year: "2021",
      content:
        "Pending changes protection should not be used as a preemptive measure against violations that have not yet occurred. Like semi-protection, PC protection should '''never''' be used in genuine content disputes, ",
      detailcontent: "Detail Content",
      sourceaddress: "Source Address",
      sourcerating: "5",
    });
    this.timeline.push({
      title: "Title",
      year: "2021",
      content:
        "Pending changes protection should not be used as a preemptive measure against violations that have not yet occurred. Like semi-protection, PC protection should '''never''' be used in genuine content disputes, ",
      detailcontent: "Detail Content",
      sourceaddress: "Source Address",
      sourcerating: "5",
    });

    this.bottomtext = `### Summary

Pending changes protection should not be used as a preemptive measure against violations that have not yet occurred. Like semi-protection, PC protection should '''never''' be used in genuine content disputes, where there is a risk of placing a particular group of editors (unregistered users) at a disadvantage. Pending changes protection should not be used on articles with a very high edit rate, even if they meet the aforementioned criteria. Instead, semi-protection should be considered.
`;

    this.pageitems.push({
      type: "text",
      value: this.toptext,
    });
    // this.pageitems.push({
    //   type: "timeline",
    //   value: this.timeline,
    //   title: "Example Timeline",
    // });
    // this.pageitems.push({
    //   type: "text",
    //   value: this.bottomtext,
    // });
  },
  methods: {
    openRefGetter(objref, field) {
      // console.log(objref);
      this.objref = objref;
      this.objreffield = field;

      this.refgetterdialog = true;
    },
    updateField(field, newtext) {
      //   this.pageitems[index][field] = value;
      field[this.objreffield] = newtext;
      this.refgetterdialog = false;
      console.log(field);
      console.log(newtext);
    },
    cleanText(text) {
      // replace two new lines with br
      //   text = text.replace(/\n\n\n/g, "<br />");
      text = text.replace(/\\np/g, "<br />");
      text = text.replace(/\\np\n/g, "<br />");

      return text;
    },
    addTextItem() {
      this.pageitems.push({
        type: "text",
        value: "",
      });
    },
    addTimelineItem() {
      this.pageitems.push({
        type: "timeline",
        value: [],
        title: "",
      });
    },
    addTimeline(item) {
      item.push({
        title: "",
        year: "",
        content: "",
        detailcontent: "",
        sourceaddress: "",
        sourcerating: "5",
      });
    },
  },
};
</script>

<style scoped>
.mdimg {
  border: 1px solid #ccc;
}
.position-relative {
  position: relative;
}
.file-upload-btn {
  right: 20px;
  bottom: 40px;
  position: absolute;
}
</style>