<template>
  <div>
    <v-container class="max-w-100">
      <v-row class="adjust-input-height">
        <!-- <v-btn @click="startAnnotate"> Annotate</v-btn> -->
        <v-col cols="12" sm="12" md="6" lg="4">
          <v-autocomplete
            label="Book"
            :items="booklist"
            item-title="name"
            item-value="key"
            v-model="book"
            variant="outlined"
          ></v-autocomplete>
          <div class="d-flex">
            <v-text-field
              class="mr-3"
              label="Page number"
              v-model="page"
              variant="outlined"
            ></v-text-field>
            <v-text-field
              label="Image Width"
              v-model="imagewidth"
              variant="outlined"
            ></v-text-field>
            <v-btn
              class="min-h-46 ml-3"
              @click="getBookPage"
              :loading="getpageloading"
              >Get</v-btn
            >
            <v-btn class="min-h-46 ml-3">Image</v-btn>
          </div>
          <div class="d-flex">
            <v-text-field label="Out url" variant="outlined"></v-text-field>
            <v-btn class="min-h-46 ml-3"><v-icon>mdi-clipboard</v-icon></v-btn>
          </div>
          <div class="mb-10">
            <v-btn class="mr-4 min-h-46" @click="getResult">Crop</v-btn>
            <v-btn class="mr-4 min-h-46" @click="saveRef">Save</v-btn>
            <v-btn class="min-h-46" @click="startAnnotate"> Annotate</v-btn>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="8">
          <img
            ref="imgRef"
            v-if="imgsrc && cropping === false && annotating === false"
            style="max-width: 100%"
            @click="showMarkerArea"
            :src="imgsrc"
            alt=""
            class="drawingimage"
          />
          <div v-if="cropping">
            <VuePictureCropper
              :boxStyle="{
                width: '100%',
                height: '100%',
                backgroundColor: '#f8f8f8',
                margin: 'auto',
              }"
              :img="imgsrc"
              :options="{
                viewMode: 1,
                dragMode: 'crop',
                zoomable: false,
                autoCrop: false,
                // aspectRatio: 16 / 9,
              }"
              @ready="ready"
            />
          </div>
          <div class="drawingimage" v-if="!imgsrc">
            Drag drop image here or click to upload or select from library.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { post, get } from "@/apiClient";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import * as markerjs2 from "markerjs2";
// import VueImageAnnotation from 'vue-image-annotation';
export default {
  components: {
    VuePictureCropper,
    // VueImageAnnotation,
    // cropper,
  },
  props: {
    fieldtext: {
      type: Object,
    },
    updatefunc: {
      type: Function,
    },
    field: {
      type: String,
    },
  },
  data: () => ({
    booklist: [],
    book: "",
    page: "",
    imgsrc: "",
    imagewidth: 600,
    cropping: false,
    annotating: false,
    getpageloading: false,
  }),
  mounted: async function () {
    // console.log(this.fieldtext);
    get("library/getbooklist")
      .then((res) => {
        this.booklist = res.data;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    saveRef: async function () {
      // console.log(this.fieldtext)
      // this.updatefunc(this.fieldtext, this.fieldtext[this.field] + " " + "test")
      post("library/saveref", {
        image: this.imgsrc,
      })
        .then((response) => {
        //   console.log(data);
            this.updatefunc(this.fieldtext, this.fieldtext[this.field] + `\n\n<br> <img src="${response.data.url}" class="mdimg" />\n\n ###### <a href="">The ref here</a> \n\n`);
        })
        .catch((err) => console.log(err));
      // if (this.fieldtext.type == "text") {
      //     this.updatefunc(this.fieldtext, this.fieldtext.value + " " + "test")
      // } else {
      //     console.log(this.fieldtext)
      //     // this.updatefunc(this.fieldtext, this.imgsrc)
      // }
      // this.updatefunc(this.fieldtext, this.fieldtext+ " " + "test")
      // this.fieldtext = this.fieldtext + " " + "test";
    },
    getBookPage: async function () {
      this.getpageloading = true;
      post("library/getbookpage", {
        book: this.book,
        page: this.page,
        imagewidth: this.imagewidth,
      })
        .then((data) => {
          //   console.log(data);
          this.getpageloading = false;
          this.imgsrc = data.data;
        })
        .catch((err) => {
          this.getpageloading = false;
          console.log(err);
        });
    },
    async getResult() {
      if (!this.cropping) {
        this.cropping = true;
        return;
      }
      if (!cropper) return;
      const base64 = cropper.getDataURL();
      const blob = await cropper.getBlob();
      if (!blob) return;

      const file = await cropper.getFile({
        fileName: "cropped.png",
      });

      console.log({ base64, blob, file });
      this.imgsrc = base64;
      this.cropping = false;
      //   this.result.blobURL = URL.createObjectURL(blob)
      //   this.isShowModal = false
    },
    startAnnotate() {
      console.log("start annotate");
      const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);
      console.log(markerArea);
      markerArea.addEventListener("render", (event) => {
        console.log(event);
        this.imgsrc = event.dataUrl;
      });
      markerArea.show();
    },
  },
  $refs: {
    imgRef: HTMLImageElement,
  },
};
</script>

<style scoped>

.drawingimage {
  border: 1px solid #ccc;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>