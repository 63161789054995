<template>
  <div class="content-wrapper">
    <!-- <TopBarWhiteBackground /> -->
    <div class="contact-banner">
      <h2>Get In Touch</h2>
      <h3>Leave a Message</h3>
      <div class="form-card">
        <form>
          <v-text-field color="primary" v-model="formdata.name" label="Name (Required)" variant="solo"></v-text-field>
          <v-text-field color="primary" v-model="formdata.email" type="email" label="Email (Required)" variant="solo"></v-text-field>
          <v-text-field color="primary" v-model="formdata.subject" label="Subject" variant="solo"></v-text-field>
          <v-textarea color="primary"  v-model="formdata.message" label="Enter Your Message" variant="solo"></v-textarea>
          <div class="d-flex justify-end">
            <v-btn color="primary" @click="submitForm">Submit</v-btn>
          </div>
        </form>
      </div>
    </div>
    <!--Banner End Here-->
    <!-- <FooterView /> -->
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
// import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
// import FooterView from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    // TopBarWhiteBackground,
    // FooterView,
  },
  name: "Contact",
  data: () => ({
    formdata: {
      name: "",
      email: "",
      phone: "",
      designation: "",
      clinic: "",
      message: "",
      subject: "",
    },

  }),
  methods: {
    async submitForm() {
      try {
       let fd = JSON.parse(JSON.stringify(this.formdata));
        // console.log(fd);
        fd.subject = fd.subject + " OneClinic";
        axios.post("https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/", fd)
          .then((response) => {
            // console.log(response);
            // reset form
            this.formdata = {
              name: "",
              email: "",
              phone: "",
              designation: "",
              clinic: "",
            };
          })
          .catch((error) => {
            console.log(error);
          });
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>