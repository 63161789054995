import { API_CLIENT } from "./interceptor";

export function get(url) {
  return API_CLIENT.get(url);
}

export function post(url, payload) {
  return API_CLIENT.post(url, payload);
}

export function put(url, payload) {
  return API_CLIENT.put(url, payload);
}

export function remove(url) {
  return API_CLIENT.delete(url);
}
