<template>
    <div>
        <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
            <h1>Library</h1>
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>

<script>
import { post } from "@/apiClient"
export default {
    mounted: async function () {
        post("library/getbookpage")
            .then((data) => {
                console.log(data);
            })
            .catch((err) => console.log(err));
    },

}
</script>

<style>

</style>