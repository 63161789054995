<template>
  <div>
    <v-app-bar color="gray-darken-4" flat>
      <!-- <template v-slot:image>
        <v-img
          gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"
        ></v-img>
      </template> -->

      <template v-slot:prepend>
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      </template>

      <v-app-bar-title>
        <!-- <v-router-link :to="{ name: 'Home' }"> -->
          <a href="/" class="titlelink">
            Some long title
          </a>
        <!-- </v-router-link> -->
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>
    <!-- <v-navigation-drawer
        v-model="drawer"
        location="top"
        temporary
      >
        <v-list
          :items="items"
        ></v-list>
      </v-navigation-drawer>      -->
  </div>
  <!--Show on Mobile View-->
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        title: "Foo",
        value: "foo",
      },
      {
        title: "Bar",
        value: "bar",
      },
      {
        title: "Fizz",
        value: "fizz",
      },
      {
        title: "Buzz",
        value: "buzz",
      },
    ],
  }),
};
</script>

<style scoped>
.titlelink {
  /* color: white; */
  text-decoration: none;
  

  
}
/* visited color black */
.titlelink:visited {
  color: black;
}
.logo {
  width: 220px;
}
.cloud-top-nav {
  padding: 0 !important;
  box-shadow: 0 0 0 0 #cecece !important;
}
.cloud-top-nav:deep(.v-toolbar__content) {
  justify-content: space-between;
}
.cloud-top-nav ul {
  display: flex;
  align-items: center;
  margin: 6px 0 0 80px;
}
.cloud-top-nav ul li {
  list-style: none;
  margin-right: 40px;
}
.cloud-top-nav ul li a {
  color: #121314;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
}
.cloud-top-nav ul li a.router-link-active {
  color: #2f74bf;
}
.cloud-top-nav ul li a:hover {
  color: #2f74bf;
}
.cloud-top-nav .v-btn {
  letter-spacing: 0.3px;
}
.home-drawer {
  display: none;
  top: 0 !important;
  width: 100% !important;
  height: auto !important;
  max-height: fit-content;
  z-index: 1006 !important;
  box-shadow: 0 0 0 0 #cecece;
}
.icon-size-32:deep(.v-btn__content .v-icon) {
  font-size: 32px;
}
.home-drawer .v-list .v-list-item {
  display: flex;
  min-height: auto;
  justify-content: center;
  padding: 15px 15px 12px 15px;
  border-top: 1px solid #f1f1f1;
}
.home-drawer .v-list .v-list-item:deep(.v-list-item-title) {
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.show-mobile-view {
  display: none;
}
/* Extra Small Media Query Start Here */
@media (min-width: 350px) and (max-width: 600px) {
  .cloud-top-nav {
    padding: 0 !important;
  }
  .logo {
    width: 170px;
  }
  .home-drawer {
    display: inline-block;
  }
  .show-mobile-view {
    display: inline-block;
  }
  .cloud-top-nav ul {
    display: none;
  }
}
/* Extra Small Media Query End Here */

/* Small Media Query Start Here */
@media (min-width: 601px) and (max-width: 960px) {
  .cloud-top-nav {
    padding: 0 !important;
  }
  .home-drawer {
    display: inline-block;
  }
  .show-mobile-view {
    display: inline-block;
  }
  .cloud-top-nav ul {
    display: none;
  }
}
/* Small Media Query End Here */
</style>