<template>
  <div class="content-wrapper">
    <div class="banner-features">
      <TopBarWhiteBackground />
      <div class="align-box">
        <v-container class="pt-0 pb-0">
          <div class="features-box">
            <h2>Our Key Features</h2>
            <p>
              Our healthcare management solution provides a comprehensive and efficient platform
              for healthcare professionals to enhance patient care, improve workflows, and
              promote patient engagement.
            </p>
            <v-btn color="primary" @click="$router.push('/contact')">Request Demo</v-btn>
          </div>
        </v-container>
      </div>
    </div>
    <!--Banner End Here-->
    <div class="professional-box">
      <v-container class="custom-adjustment">
        <v-row gutters="16">
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img class="width-160" src="@/assets/images/features-images/fast-input.svg" alt=""></v-img>
              </span>
              <h3>Lightning-Fast Input of Patient Records & Offline Functionality</h3>
              <p>
                Save time and reduce errors with our rapid record input feature,
                designed to streamline your workflow. Experience unparalleled Efficiency
                with our fully offline mode, ensuring that you can access and manage
                patient records anytime, anywhere, even without an internet connection.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img src="@/assets/images/features-images/prescriptions.svg" alt=""></v-img>
              </span>
              <h3>Generation of professional Printable Prescriptions & WhatsApp Delivery</h3>
              <p>
                Ensure patient safety and adherence with professionally formatted, legible
                prescriptions. Both urdu and english are supported. Minimize misinterpretation and errors with our clean prescription
                generation feature. Send prescriptions via WhatsApp messages, making it easier than
                ever for your patients to receive and understand their treatment plans.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img class="width-140" src="@/assets/images/features-images/shortcuts.svg" alt=""></v-img>
              </span>
              <h3>Create Shortcuts, Presets & Prescription Templates</h3>
              <p>
                Boost efficiency with our custom shortcuts and presets. Tailor your most-used
                treatment plans and prescription templates, saving time and minimizing errors.
                Streamline your practice and focus on delivering exceptional patient care.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img src="@/assets/images/features-images/uploads.svg" alt=""></v-img>
              </span>
              <h3>Secure Documents and Image Upload</h3>
              <p>
                Keep a comprehensive medical history. Easily upload documents and images,
                allowing for visual diagnostics, sharing lab reports, and maintaining complete
                patient records.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img src="@/assets/images/features-images/med-records.svg" alt=""></v-img>
              </span>
              <h3>Comprehensive Drug Guide</h3>
              <p>
                Say goodbye to guesswork. Our system comes pre-equipped with an extensive drug suide,
                providing you with quick and reliable information on medications to make informed
                deci-sions instantly.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img src="@/assets/images/features-images/patient-access.svg" alt=""></v-img>
              </span>
              <h3>Patient Access to Medical Records</h3>
              <p>
                Empower patients to take control of their healthcare. Let them access their records,
                view last results, and stay informed about their medical history, fostering
                transparency and engagement.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img src="@/assets/images/features-images/appt-booking.svg" alt=""></v-img>
              </span>
              <h3>Online Appointment Booking (Upcoming)</h3>
              <p>
                Coming soon! Enjoy the convenience of allowing your patients to schedule appointments
                online, reducing administrative burdens and ensuring a smooth patient experience.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img src="@/assets/images/features-images/telehealth.svg" alt=""></v-img>
              </span>
              <h3>Telemedicine/Video Appointments (Upcoming)</h3>
              <p>
                The future of healthcare is here. With our upcoming telemedicine/video appointments
                feature, you can provide remote consultations, expanding your reach and improving
                patient access to care.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col class="border-boxes" cols="12" md="6" lg="4">
            <div class="professional-card">
              <span class="images-wrapper">
                <v-img class="width-160" src="@/assets/images/features-images/contraindication.svg" alt=""></v-img>
              </span>
              <h3>Automated Warnings for Contraindications (Upcoming)</h3>
              <p>
                Coming soon! Our intelligent system will automatically alert you to potential
                contraindications, enhancing patient safety and helping you make well-informed
                treatment decisions.
              </p>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" md="12">
            <div class="d-flex justify-center">
              <v-btn color="primary" class="request-demo-btn">Request Demo</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Professional Boxes End Here-->
    <FooterView />
  </div>
</template>

<script>

// import { useRoute, useRouter } from "vue-router";
import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
import FooterView from "@/components/Footer.vue";
export default {
  components: {
    TopBarWhiteBackground,
    FooterView,
  },
  setup() {},
  name: "FeaturesTemplate",
  data: () => ({

  }),
};
</script>

<style scoped></style>