<template>
  <authenticator>
    <template v-slot="{ user, signOut }">
      <h1>Hello {{ user.attributes.email }}!</h1>
      <button @click="signOut">Sign Out</button>
    </template>
  </authenticator>
</template>
<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
</script>
<script>
import { Auth, Hub } from "aws-amplify";
export default {
    mounted: async function () {
        Hub.listen("auth", (data) => {
            // const { payload } = data;
            // this.onAuthEvent(payload);
            Auth.currentSession()
                .then((data) => {
                    this.$router.push({ name: "Home" });
                })
                .catch((err) => console.log(err));
        });
    },
};
</script>

<style>
</style>