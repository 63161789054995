<template>
  <v-app>
    <v-main>
      <!-- <TopBarWhiteBackground /> -->

      <router-view />
      <!-- <v-footer class="bg-grey-lighten-1">
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="link in links"
            :key="link"
            color="white"
            variant="text"
            class="mx-2"
            rounded="xl"
          >
            {{ link }}
          </v-btn>
          <v-col class="text-center mt-4" cols="12">
            {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
          </v-col>
        </v-row>
      </v-footer> -->
    </v-main>
  </v-app>
</template>

<script>
// import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
import { Auth } from "aws-amplify";
export default {
  data: () => ({
    drawer: false,
    group: null,
    timer: null,
  }),
  components: {
    // TopBarWhiteBackground,
    // FooterView,
  },
  name: "App",

  methods: {
    tokencheck: function () {
      let route = this.$route;
      Auth.currentSession()
        .then((session) => {
          // console.log(session.idToken.payload.exp * 1000 - Date.now());
          this.timer = setTimeout(() => {
            console.log("token refresh");
            this.tokencheck();
          }, session.idToken.payload.exp * 1000 - Date.now() - 300000);
          // console.log(session);
          if (!session.idToken.payload["cognito:groups"].includes("Admin")) {
            this.$router.push({ name: "NoAccess" });
          } else {
            localStorage.setItem("token", session.idToken.jwtToken);
          }
        })
        .catch((err) => {
          if (route.name != "Login") {
            this.$router.push({ name: "Login" });
          }
        });
    },
  },
  mounted() {
    //scroll to top on route change
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
    this.tokencheck();
    // let
  },
};
</script>

<style scoped>
.v-main {
  padding-top: 0;
  padding-left: 0;
  min-height: 100vh;
}

.v-app-bar {
  padding: 0;
}

@media (min-width: 350px) and (max-width: 767px) {
  .v-main {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1150px) {
  .v-main {
    padding-left: 0;
  }
}
</style>
