<template>
  <div class="content-wrapper">
    <div class="banner-nav">
      <!-- <TopBarWhiteBackground /> -->
      <v-container>
        <div class="top-space">
          <h1>Welcome to admin front end</h1>
        </div>

        <v-row gutters="16">
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Homepage</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions class="pl-4 pr-4 pb-4">
                <v-btn variant="outlined" color="primary"> Manage </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Articles</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions class="pl-4 pr-4 pb-4">
                <v-btn variant="outlined" color="primary"> Manage </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">Library</div>
                  <div class="text-caption">
                    Greyhound divisely hello coldly fonwderfully
                  </div>
                </div>
              </v-card-item>
              <v-card-actions class="pl-4 pr-4 pb-4">
                <!-- <v-btn variant="outlined" color="primary"> Manage </v-btn> -->
                <router-link to="/library">
                  <v-btn variant="outlined" color="primary"> Manage </v-btn>
                </router-link>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>
    </div>
    <!--/// Banner End Here ///-->
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
// import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
// import FooterView from "@/components/Footer.vue";
// import VueMarkdown from 'vue-markdown'
// import Markdown from "vue3-markdown-it";

import axios from "axios";
export default {
  components: {
    // TopBarWhiteBackground,
    // FooterView,
    // VueMarkdown
    // Markdown,
  },
  setup() {},
  name: "Home",
  methods: {},
  data: () => ({}),
};
</script>