<template>
    <div>
        <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
            <h1>Access Denied</h1>
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>