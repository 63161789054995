import { colors } from "@/theme";
import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
export default createVuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme: {
        colors: {
          primary: colors.primary,
          secondary: colors.secondary,
          success: colors.success,
          error: colors.error,
        },
      },
    },
  },
});