import { createWebHashHistory, createRouter, createWebHistory } from "vue-router";

// Views

import Home from "@/views/Home.vue";
import FeaturesTemplate from "@/views/FeaturesTemplate.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import Editor from "@/views/Editor.vue";
import Login from "@/views/Login.vue";
import NoAccess from "@/views/NoAccess.vue";
import Library from "@/views/Library.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/library",
    name: "Library",
    component: Library,
  },
  {
    path: "/noaccess",
    name: "NoAccess",
    component: NoAccess,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/editor",
    name: "Editor",
    component: Editor,
  },
  {
    path: "/features",
    name: "FeaturesTemplate",
    component: FeaturesTemplate,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
