<template>
  <div class="content-wrapper">
    <TopBarWhiteBackground />
    <div class="about-banner">
      <v-container class="h-100">
        <div class="align-box">
          <div class="about-box">
            <h2>Welcome To Oneclinic</h2>
            <p>
              Our Journey began with a vision to revolutionize the capture of electronic medical records.
              Today, we stand as a dedicated team of professionals committed to empowering healthcare
              providers and institutions with cutting-edge solutions.
            </p>
          </div>
        </div>
      </v-container>
    </div>
    <!--Banner End Here-->
    <div class="about-content">
      <v-container>
        <v-row gutters="12" class="justify-center">
          <v-col cols="12" md="12">
            <h3>Our Mission</h3>
            <p>
              Our mission is simple yet profound: to optimize electronic medical records management,
              democratize patient access to their own records, and facilitate doctor-patient
              interactions with cutting edge technologies. We belive that technology has the
              power to enhance healthcare outcomes and patient expetiences, and we're driven to make
              this a reality for our clients.
            </p>
            <h3>Why Choose Us?</h3>
            <p class="mb-6">
              <span>Expertise:</span> Our team comprises seasoned experts in healthcare and technology.
              We combine industry knowledge with innovation to create solutions that truly meet your needs.
            </p>
            <p class="mb-6">
              <span>Innovation:</span> We are at the forefront of healthcare technology, continuously
              evolving and adapting our products to stay ahead of the curve. Our commitment to
              innovation means you can always rely on our solutions to be cutting-edge.
            </p>
            <p>
              <span>Client-Centric:</span> Your success is our success. We are dedicated to
              building strong, long-lasting relationships with our clients, providing exceptional
              support, and being responsive to your needs.
            </p>
            <h3>What We Offer</h3>
            <p>
              <span>Comprehensive EMR Solutions:</span> Introducing OneClinic, our all-encompassing
              product for electronic medical records and prescription management in Pakistan.
              OneClinic not only offers highly efficient input capabilities but also integrates
              cutting-edge technologies such as multi-lingual translation, advanced text messaging,
              doctor-patient interaction features, telemedicine capabilities, and Al-driven doctor
              support.
            </p>
            <h3>Join Us on Our Journey</h3>
            <p class="mb-6">
              We invite you to join us on our mission to transform healthcare administration and
              patient care. Whether you are a healthcare professional, an institution, or a patient
              looking for a better healthcare experience, OneClinic is here to make a positive
              impact.
            </p>
            <p>
              Contact us today to explore how our solutions can elevate your practice and improve
              patient outcomes. Together, we can shape the future of healthcare.
            </p>
            <div class="d-flex justify-center pt-5">
              <v-btn color="primary">Request Demo</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--About Content End Here-->
    <FooterView />
  </div>
</template>

<script>
  // import { useRoute, useRouter } from "vue-router";
  import TopBarWhiteBackground from "@/components/TopBarWhiteBackground.vue";
  import FooterView from "@/components/Footer.vue";
  export default {
    components: {
      TopBarWhiteBackground,
      FooterView,
    },
    name: "About",
    data: () => ({

    }),
  };
</script>
